.aboutSection {
    position: relative;
}

.aboutHeading {
    margin-top: 60px;
    font-size: calc(1.5rem + 0.5vw);
}

@media screen and (min-width: 500px) {
    .aboutHeading {
        font-size: 30px;
    }
}

@media screen and (min-width: 750px) {
    .aboutHeading {
        font-size: 42px;
    }
}

.aboutContainer {
    max-width: 900px;
}

.aboutBlurb {
    white-space: pre-wrap;
    margin-top: 15px;
}

.aboutPortrait {
    border-radius: 100%;
    width: 50vw;
    max-width: 325px;
}

@media screen and (max-width: 700px) {
    .aboutPortrait {
        display: none;
    }
}

.buttonExt {
    margin: 5px 10px;
}

.buttonExtA, .buttonExtA:hover {
    color: whitesmoke;
    text-decoration: none;
    text-decoration-line: none;
}

.buttonExt i {
    font-size: 32px;
}

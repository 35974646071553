.projectHeading {
    font-size: calc(1.5rem + 0.5vw);
    margin-top: 60px;
}

@media screen and (min-width: 500px) {
    .projectHeading {
        font-size: 30px;
    }
}

@media screen and (min-width: 750px) {
    .projectHeading {
        font-size: 42px;
    }
}

.ProjectCardWrapper {
    margin: 10px 10px 25px;
}

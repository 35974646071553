.skillSection {
  position: relative;
}

.skillHeading {
  font-size: calc(1.5rem + 0.5vw);
  margin-top: 60px;
}

@media screen and (min-width: 500px) {
  .skillHeading {
      font-size: 30px;
  }
}

@media screen and (min-width: 750px) {
  .skillHeading {
      font-size: 42px;
  }
}

.deviconGridContainer {
  margin-top: 15px;
  max-width: 900px;
}

.skillCard {
  display: inline-block;
  margin: 10px;
}

.introSection {
    position: relative;
    text-align: center;
    height: 100vh;
    width: 100vw;
    background: url('../images/cherry-blossom-nyc-2.jpg');
    background-size: cover;
    background-position: center;
}

.introHeading1,
.introHeading2 {
    padding: 8px 0px;
    margin: 0px;
}

.introHeading1 {
    font-size: 9vw;
}

.introHeading2 {
    font-size: 7vw;
}

@media screen and (max-width: 300px) {
    .introHeading1 {
        font-size: 27px;
    }

    .introHeading2 {
        font-size: 21px;
    }
}

@media screen and (min-width: 500px) {
    .introHeading1 {
        font-size: 45px;
    }

    .introHeading2 {
        font-size: 35px;
    }
}

@media screen and (min-width: 750px) {
    .introHeading1 {
        font-size: 63px;
    }

    .introHeading2 {
        font-size: 49px;
    }
}

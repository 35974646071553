.PhotoCarousel {
    position: relative;
}

.CarouselIcon {
    /* position: absolute; */
    font-size: 50px;
    color: grey;
    /* -webkit-text-stroke: 5px; */
    /* top: 187.5px; */
    /* font-weight: 100px; */
}

.controlLayer {
    position: absolute;
    padding: 2.5px;
}

/* .CarouselIconRight {
    left: 550px;
} */



/* .CarouselIconWhite {
    -webkit-text-stroke: 0px;
    color: white;
}

.clickBox {
    position: absolute;
    height: 450px;
    width: 75px;
}

.clickBoxRight {
    left: 525px;
} */

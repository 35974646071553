.burgerIcon {
    font-size: 32px;
    margin: 5px 10px;
}

.burgerMenuButtonsArea {
    position: absolute;
    background-color: rgb(51, 51, 51);
}

.burgerMenuButton>.navButtonScroll {
    padding-right: 50px;
}
